<template>
  <section class="about" style="background: #FFF;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="tnf-top-banner-s"
      >
        <v-layout
          class="hidden-sm-and-down"
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
          <v-flex style="position: absolute; bottom: 40px;" class="tnf-submenu">
            <template>
              <v-tabs
                v-model="tabSubMenu"
                light
                grow
              >
                <v-tab
                  v-for="(menu, k) in subMenu"
                  :key="k"
                  :to="'/' + menu.key"
                >
                  {{menu.name}}
                </v-tab>
              </v-tabs>
            </template>
          </v-flex>
        </v-layout>
      </v-img>
      <v-container  grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm12 md12 lg12 class="tnf-story-top">
                <h2>Brand story</h2>
                <h4>品牌故事</h4>
                <h5> </h5>
                <h3>一见倾心</h3>
                <v-layout wrap>
                  <v-flex xs12 sm12 md7 lg7>
                    <p>年轻的第三代JOHANNES TRYBA，邂逅法国美女设计师SOPHIE，一见倾心；SOPHIE则顾虑德式的严谨，门窗行业的刻板机械，无法与她浪漫艺术的法式情怀融洽相处，“正如同直板的帘片坚硬牢固，却无法弯曲变形”迟迟不愿接受。</p>
                  </v-flex>
                  <v-flex xs12 sm12 md5 lg5 pl-lg-12>
                    <v-img
                      height="348"
                      src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/story_right.png"
                      aspect-ratio="1"
                      contain
                      position="left"
                    ></v-img>
                  </v-flex>
                </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="tnf-story-middle">
            <v-layout wrap>
              <v-flex xs12 sm12 md4 lg4 pr-md-6>
                <v-img
                  height="316"
                  src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/story_left.jpg"
                  aspect-ratio="1"
                  contain
                  position="left"
                >
                </v-img>
              </v-flex>
              <v-flex xs12 sm12 md8 lg8>
                <h6>法国门窗品牌</h6>
                <h3>特诺发的新象征</h3>
                <h4>德国工艺的蓝色蝴蝶成为了特诺发的新象征</h4>
                <v-layout wrap>
                  <v-flex xs12 sm12 md7 lg7>
                    <p>SOPHIE喜欢蝴蝶，JOHANNES 用卷帘片做了一只蓝色的蝴蝶送给她，“即使直板坚硬的帘片也可以做出美丽的蝴蝶……”当SOPHIE的姓氏改为TRYBA的时候，这只象征法式优雅，德国工艺的蓝色蝴蝶也成为了特诺发的新象征。TRYBA特诺发见证了法国气质与德国品质的融合,并把这一切用自己的产品展现。</p>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="tnf-story-bottom">
            <v-layout wrap>
              <v-flex xs12 sm12 md6 lg6>
                <h2>以金刚蝴蝶为指引</h2>
                <h3>自由灵动地展翅飞翔</h3>
                <p>随着特诺发总部的不断发展壮大，最终以一只象征德国匠心工艺及法国浪漫灵魂的金刚蝴蝶为指引，自由灵动地展翅飞翔，将特诺发的见证及产品带到了中国。TRYBA 是以家族姓氏命名的门窗品牌。1956年第二代WALTER TRYBA 在波兰建立工厂。 随市场变化，1980年，TRYBA 家族迁居德法边境阿尔萨斯，开始工业化自动化的门窗生产加工。德国式的严谨工艺获得法国市场的好评。</p>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <h1 class="tnf-large-text">BRAND STORY</h1>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 1,
    innerWidth: window.innerWidth,
    about: {
      'introduction': null
    },
    tabSubMenu: null,
    subMenu: [
      { key: 'about', name: '公司简介' },
      { key: 'story', name: '品牌故事' },
      { key: 'history', name: '发展历程' },
      { key: 'honor', name: '企业荣誉' },
      { key: 'assistant', name: '门窗管家' },
      { key: 'partner', name: '合作伙伴' }
    ],
    bannerHeight: window.innerWidth > 960 ? 460 : 200
  }),
  created () {
    // this.getAbout()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      let links = []
      if (this.$store.getters.companyInfo.links) {
        links = this.$store.getters.companyInfo.links
      }
      return {
        links: links,
        banners: banners
      }
    }
  },
  methods: {
    getAbout () {
      this.https.get('about').then(response => {
        if (response.code === 0) {
          this.about = response.data
        }
      })
    }
  }
}
</script>
<style lang="css">
  @import '../css/story.css';
</style>
